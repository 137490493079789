import * as React from "react"
import { graphql,navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Recaptcha from 'react-google-recaptcha'

const RECAPTCHA_KEY = '6Lczq8QgAAAAAEbsDkS1eNgdePfheM69ExoBWWvU'
// const RECAPTCHA_KEY = process.env.GATSBY_APP_SITE_RECAPTCHA_KEY
// if (typeof RECAPTCHA_KEY === "undefined") {
//   throw new Error(`
//   reCAPTCHAキーが登録されていません
//   `)
// }

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactUsRECAPTCHA = ({ data, location }) => {
  
  const siteTitle = data.site.siteMetadata?.title || `Title`

  const [state, setState] = React.useState({})
  const recaptchaRef = React.createRef()
  const [recaptchaStatus, setRecaptchaStatus] = React.useState(false)

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const recaptchaValue = recaptchaRef.current.getValue()
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      redirect: 'follow', 
      body: encode({
        'form-name': form.getAttribute('name'),
        'g-recaptcha-response': recaptchaValue,
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error))
  }

  const recaptchaSuccess = () => {
    setRecaptchaStatus(true)
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="お問い合わせ｜株式会社スタートアッププラス" />

      <div className="block xl:flex justify-between">

        <div id="pageTitle" className="relative xl:fixed w-full xl:w-48 h-auto xl:h-full text-white bg-mail bg-no-repeat bg-center bg-cover z-20 ">
          <h1 className="font-Copperplate text-center text-2xl sm:text-3xl pt-10 pb-20">Contact Us</h1>
        </div>

        <div id="main" className="static xl:ml-48 w-full">
          <div className=" w-11/12 max-w-[800px] mx-auto py-10 sm:py-10">

            <section id="ContactUs" className="mb-20">
              <h2 className="text-2xl border-b border-key-sand mb-10 py-3 ">
                お問い合わせ
              </h2>
              <form 
                onSubmit={ () => {navigate("/form_sent")}} 
                action="https://docs.google.com/forms/u/0/d/e/1FAIpQLScGcnYS6xdYTTa0QOZXaZIwJqLkDXWHEE4OleN_FdDU3jIdpA/formResponse"
                target="hidden_iframe"
                name="contactus">

                <input type="hidden" name="form-name" value="contactus" />
                <div className="relative z-0 mb-10 w-full group">
                  <input type="text" name="entry.1051269882" id="company" onChange={handleChange}
                    pattern="[^\x20-\x7E]*"
                    className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " required />
                  <label htmlFor="company" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    会社名<small>(全角)</small>　<small className=" text-key-pink">※必須</small>
                  </label>
                </div>
                <div className="relative z-0 mb-10 w-full group">
                  <input type="text" name="entry.724033773" id="department" onChange={handleChange}
                    className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" "/>
                  <label htmlFor="department" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    部署名・役職名
                  </label>
                </div>
                <div className="relative z-0 mb-10 w-full group">
                  <input type="text" name="entry.1575271562" id="name" onChange={handleChange}
                    pattern="[^\x20-\x7E]*"
                    className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " required />
                  <label htmlFor="name" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    お名前<small>(全角)</small>　<small className=" text-key-pink">※必須</small>
                  </label>
                </div>
                <div className="relative z-0 mb-10 w-full group">
                  <input type="email" name="entry.726732867" id="email" onChange={handleChange}
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" 
                    className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " required />
                  <label htmlFor="email" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    メールアドレス　<small className=" text-key-pink">※必須</small>
                  </label>
                </div>
                <div className="relative z-0 mb-10 w-full group">
                  <input type="tel" name="entry.364564378" id="phone" onChange={handleChange}
                    className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " required />
                  <label htmlFor="phone" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    電話番号　<small className=" text-key-pink">※必須</small>
                  </label>
                </div>
                <div className="relative z-0 mb-10 w-full group">
                  <textarea name="entry.309371426" id="message" rows="4" onChange={handleChange}
                    className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " required ></textarea>
                  <label htmlFor="message" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    お問い合わせ内容　<small className=" text-key-pink">※必須</small>
                  </label>
                </div>
                <div className="mb-10">
                  <strong>【個人情報の管理について】</strong>
                  <p className="p-2 text-sm">
                  お問い合せの際にお送りいただく個人情報は、お問い合せへの返答に必要な連絡等の目的の範囲内で利用させていただきます。
                  その他個人情報の取扱いに関する詳細については、<br />
                  当社のプライバシーポリシー（<a href="https://www.startup-plus.com/privacy-policy/" target="_blank" rel="noopener noreferrer">http://www.startup-plus.com/privacy-policy/</a>）をご確認ください。<br />
                  個人情報の管理について同意いただける場合は下記「同意する」にチェックを付けて下さい。
                  </p>
                  <label htmlFor="agree" className="p-2">
                    <input type="checkbox" name="agree" onChange={handleChange} required />
                    <span className="ml-2">同意する</span><small className=" text-key-pink">※必須</small>
                  </label>

                </div>
                <div className="mb-10">
                  <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} onChange={recaptchaSuccess}/> 
                </div>
                
                <button type="submit" id="button" disabled={!recaptchaStatus}
                  className="text-white bg-key-sand hover:bg-key-pink focus:ring-4 font-medium rounded-lg text-sm w-full sm:w-1/2 px-5 py-2.5 text-center">
                    送 信
                </button>
              </form>
              <iframe title="hidden_iframe" name="hidden_iframe" style={{display:'none'}}></iframe>

            </section>

          </div>
        </div>
      </div>

    </Layout>
  )
}

export default ContactUsRECAPTCHA

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`